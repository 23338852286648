import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams
} from "react-router-dom";
import './App.css';

import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    CssBaseline,
    Stack,
    Button,
    createTheme,
    useTheme,
    ThemeProvider,
    Container,
    Menu,
    Avatar,
    Box,
    Tooltip,
    MenuItem,
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';


import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import useMediaQuery from '@mui/material/useMediaQuery';

import WorldMap from './Components/WorldMap.js';
import LineGraph from './Components/Charts/LineGraph'
import {LoginForm} from './Components/Authentication/Login'
import ProtectedRoute from "./Components/Authentication/ProtectedRoute";
import {Logout} from "./Components/Authentication/Logout";
import Station from "./Components/Station";
import Stations from './Components/Stations';
import Profile from './Components/Profile';
import Settings from './Components/Settings';

import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import SettingsIcon from '@mui/icons-material/Settings';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });


function ToggleColorMode() {
    var darkMode;
    var darkMode2;
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    if (prefersDarkMode) {
        darkMode = 'dark';
        darkMode2 = 'dark';
    } else {
        darkMode = 'light';
        darkMode2 = 'light';
    }
    
    const [mode, setMode] = React.useState(darkMode);
    const colorMode = React.useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
         
          if (darkMode2 === 'light') {
            document.getElementById("root").style.setProperty("--map-tiles-filter", ('brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7)'));
            darkMode2 = 'dark';
          } else { 
            document.getElementById("root").style.setProperty("--map-tiles-filter", ('none'));
            darkMode2 = 'light';
          }
          
        //   document.getElementById("root").style.setProperty("--map-tiles-filter", (mode === 'light' ? 'brightness(0.5)' : 'brightness(1)'));
         
        },
      }),
      [],
    );
  
    const theme = React.useMemo(
      () =>
        createTheme({
          palette: {
            mode,
          },
        }),
      [mode],
    );
    return (
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </ColorModeContext.Provider>
      );
}

function App() {
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext); 
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const pages = [ 'Stations'];
    const settings = ['Profile', 'Settings', 'Logout'];

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    function displayMenubar(type) {
        
        const auth = localStorage.getItem('jwt') != null;
        if (auth) {
            switch (type) {
                case 'desktop':
                    return pages.map((page) => (
                        <Button
                            key={page}
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                            component={Link} 
                            to={page}
                        >
                            {page}
                        </Button>
                        ))
                case 'mobile':
                    return pages.map((page) => (
                        <MenuItem key={page} onClick={handleCloseNavMenu} component={Link} to={page}>
                            <Typography textAlign="center">{page}</Typography>
                        </MenuItem>
                    ))
                case 'settings':
                    return settings.map((setting) => (
                        <MenuItem key={setting} onClick={handleCloseUserMenu} component={Link} to={setting}>
                        <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                    ))
            default:
                break;
            }

            //<>
            //     <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
            //         {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            //     </IconButton>
            //     <Button color='inherit' component={Link} to="/">Map</Button>
            //     <Button color='inherit' component={Link} to="/graph">Graph</Button>
            //     <Button color='inherit' component={Link} to="/stations">Stations</Button>
            //     <Button color='inherit' component={Link} to="/logout">Logout</Button>
            //     <IconButton sx={{ ml: 1 }} component={Link} to="/settings">
            //         {theme.palette.mode === 'dark' ? <SettingsIcon/> : <SettingsIcon sx={{ color: "white" }}/>}    
            //     </IconButton>
            // </>;
        } else {
            return <></>
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
            <CssBaseline/>
            <Router>
                {/* <AppBar position="static">
                    <Toolbar>
                        <IconButton size="large" egde="start" color="inherit">
                            <RocketLaunchIcon/>
                        </IconButton>
                        <Typography variant="h6" comonent="div" sx={{flexGrow: 1}}>
                            ONERA
                        </Typography>
                        <Stack direction='row' spacing={2}>
                            {displayMenubar()}
                        </Stack>
                    </Toolbar>
                </AppBar> */}
                <AppBar position="static">
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                        <RocketLaunchIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            href="/"
                            sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            }}
                            component={Link} 
                            to="/"
                        >
                            ONERA
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            >
                            <MenuIcon />
                            </IconButton>
                            <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                            >
                                
                            {displayMenubar("mobile")}
                            </Menu>
                        </Box>
                        
                        <RocketLaunchIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h5"
                            noWrap
                            href=""
                            sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            }}
                            component={Link} 
                            to="/"

                        >
                            ONERA
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {displayMenubar("desktop")}
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                        <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                            <Tooltip title="Open settings">
                            
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Eve" 
                                    src={"https://reqres.in/img/faces/4-image.jpg"}
                                />
                            </IconButton>

                            </Tooltip>
                            <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            >
                            {displayMenubar("settings")}
                            </Menu>
                            
                        </Box>
                        
                        </Toolbar>
                        
                    </Container>
                </AppBar>
                <Routes>
                    <Route path="/login" element={<LoginForm/>}/>

                    <Route path="/" element={<ProtectedRoute/>}>
                        <Route path="/" element={<WorldMap/>}/>
                    </Route>

                    <Route path="/logout" element={<ProtectedRoute/>}>
                        <Route path="/logout" element={<Logout/>}/>
                    </Route>

                    <Route path="/stations" element={<ProtectedRoute/>}>
                        <Route path="/stations" element={<Stations/>}/>
                    </Route>

                    <Route path="/settings" element={<ProtectedRoute/>}>
                        <Route path="/settings" element={<Settings/>}/>
                    </Route>

                    <Route path="/profile" element={<ProtectedRoute/>}>
                        <Route path="/profile" element={<Profile/>}/>
                    </Route>
                    <Route path="/station/:id" element={<ProtectedRoute/>}>
                        <Route path="/station/:id" element={<Station params={useParams()}/>}/>
                    </Route>

                </Routes>
            </Router>

        </ThemeProvider>
    );
}

export default ToggleColorMode;
