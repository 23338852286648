import React from 'react';
import axios from "axios";
import {Button, Paper, Container, TextField} from "@mui/material";

export function LoginForm(){
    function login(event){
        event.preventDefault();

        const payload = {
            email: document.getElementById('email').value,
            password: document.getElementById('password').value
        }

        axios.post('https://reqres.in/api/login', payload)
            .then(response => {
                const token = response.data.token;
                localStorage.setItem('jwt', token);
                window.location.href = '/'
            })
            .catch(err => console.log(err));
    }

    return (
        <Container  maxWidth="xl">
            <Paper className="login-page">
            <form className="form">
                <TextField
                    labelText="Email"
                    id="email"
                    formControlProps={{
                        fullWidth: true
                    }}
                    type="text"
                />
                <TextField
                    labelText="Password"
                    id="password"
                    formControlProps={{
                        fullWidth: true
                    }}
                    type="password"
                />

                <Button type="button" color="primary" className="form__custom-button" onClick={login}>
                    Log in
                </Button>
            </form>
            </Paper>
        </Container>
    );
}