import { MapContainer, TileLayer, Marker } from "react-leaflet";
import 'react-leaflet-markercluster/dist/styles.min.css';
import 'leaflet/dist/leaflet.css';
import "./style.scss";
import API from "../api/api";
import React from "react";
import icon from 'leaflet/dist/images/marker-icon.png';
import L from 'leaflet';
import InfoStation from "./InfoStation";
import {CircularProgress, Paper} from '@mui/material'
import Box from '@mui/material/Box';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';

const iconPerson = new L.Icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 30),
  className: 'leaflet-div-icon'
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class WorldMap extends React.Component {
  children = [];
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      loading: true,
      stationName: "",
      stationID: 0,
      stationCountry: "",
      stationLat: 0,
      stationLong: 0,
      stationPostalCode: "",
      stationElevation: 0,
      stationRegion: "",
      mapURL: "",
    };
    this.mapRef = React.createRef();
    this.displayMarkers = this.displayMarkers.bind(this);
  }

  createMarkers(records) {
    var array = [];
  
    records.map(station => {
    
      array.push(
        <Marker 
        icon={iconPerson} key={station.name} position={[station.latitude, station.longitude]} 
        highlighted={true}
        eventHandlers={{
          click: (e) => {
            console.log(station)
            this.setState({
              stationName: station.name,
              stationID: station.name,
              stationCountry: station.country,
              stationLat: station.latitude,
              stationLong: station.longitude,
              stationPostalCode: station.postalcode,
              stationElevation: station.elevation,
              stationRegion: station.region,
              stationLevelType: station.level_type,
              
            })
            localStorage.setItem("stationData", JSON.stringify(station));
            this.openSideBar()
            this.mapRef.current.setView([station.latitude, station.longitude], 8);   
        
          }
        }}>
        </Marker>
      );
    });
    return array;
  }

  openSideBar() {
      document.getElementsByClassName("markercluster-map")[0].style.zIndex = "1";

      var sideBar = document.getElementById("sideBar");
      if(window.innerWidth < 900){
        sideBar.style.zIndex = "0";
        sideBar.style.minWidth = "100vw";
      } else {
        sideBar.style.zIndex = "1";

      }
     
      sideBar.style.display = "block";
    
  }

  loadSettings() {
    var settings = localStorage.getItem("settings");
    if (!settings) {
      localStorage.setItem("settings", JSON.stringify(
        {"lastLocation":true,"language":"English","preferedCountry":"FR", "mapType":"default"}
      ));
    }
  }

  componentDidMount() {
    this.loadSettings();
    const s = localStorage.getItem("settings")
    const settings = JSON.parse(s);
    
    API.get("station/read_cc/"+settings.preferedCountry).then(response => {
      
      this.data = this.createMarkers(response.data.data)
      this.setState({
        loading: false,
        open: true
      });
      this.displayMarkers();
    });

  }

  loadMapType(mapType) {
    var URL;
    switch (mapType) {
      case "default":
        URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
        break;
      case "satellite":
        URL = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
        break;
      case "terrain":
        URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}{r}.png";
        break;
      case "topo":
        URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/topo/{z}/{x}/{y}{r}.png";
        break;
      case "watercolor":
        URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}{r}.png";
        break;
      case "toner":
        URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png";
        break;
      case "tonerlite":
        URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png";
        break;
      case "toner-background":
        URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-background/{z}/{x}/{y}{r}.png";
        break;
      case "toner-hybrid":
        URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-hybrid/{z}/{x}/{y}{r}.png";
        break;
      case "toner-labels":
        URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-labels/{z}/{x}/{y}{r}.png";
        break;
      case "toner-lines":
        URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lines/{z}/{x}/{y}{r}.png";
        break;
      case "landscape":
        URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/landscape/{z}/{x}/{y}{r}.png";
        break;
      case "dark_matter":
        URL = "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png";
        break;
      default:
        URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
        break;
      
    }

    this.setState({
      mapURL: URL
    })
  }
  
  displayMarkers() {
    const map = this.mapRef;
  
    const markers = this.data
  
    this.setState({
        markers: markers
    });
    
    
    if (localStorage.getItem("settings")) {
      const settings = JSON.parse(localStorage.getItem("settings"));
      this.loadMapType(settings.mapType);
      
      if (settings.lastLocation) {
        if (localStorage.getItem("stationData")) {
          const stationData = JSON.parse(localStorage.getItem("stationData"));
          this.setState({
            stationName: stationData.name,
            stationID: stationData.name,
            stationCountry: stationData.country_code,
            stationLat: stationData.latitude,
            stationLong: stationData.longitude,
            stationPostalCode: stationData.postalcode,
            stationElevation: stationData.elevation,
            stationRegion: stationData.region,
            stationLevelType: stationData.level_type
          }) 
          map.current.setView([stationData.latitude, stationData.longitude], 8);
          this.openSideBar()
        }
        
        
      };
    }
    
  }

  
    

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      open: false
    });
  };

  render() {
    return (
      <div id="content">
        <Snackbar open={this.state.open} autoHideDuration={5000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
            Markers successfully loaded
          </Alert>
        </Snackbar>
        {!this.state.loading ?
        <>
        <MapContainer
          ref={this.mapRef}
          onMoveEnd={this.displayMarkers}
          className="markercluster-map"
          center={[51.0, 19.0]}
          zoom={4}
          maxZoom={18}>
          <TileLayer
            url={this.state.mapURL}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            className= 'map-tiles'
          />
        
            {!this.state.loading ? this.state.markers : null}

      </MapContainer>
      <Paper id="sideBar" elevation={12}>
        <InfoStation states={this.state}/>
      </Paper>
      </>
      : 

      <Box id="loading" justifyContent="center" alignItems="center" display="flex" flexDirection="column" width="100vw" height="100vh" sx={{fontSize: "40px"}}>
        <CircularProgress size={140} />
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
            Loading stations... <RocketLaunchIcon />
          </Box>
      </Box>

    }
    </div>
    
    );
  }
}


export default WorldMap;
