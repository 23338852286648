import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

const ProtectedRoute = ({component: Component, ...rest}) => {

    function isLoggedIn() {
        const jwt = localStorage.getItem('jwt');
        if (jwt == null) {
            return false;
        }

        //TODO: check jwt expiry
        return true;
    }

    if (isLoggedIn()) {
        return <Outlet />
    }

    return <Navigate to='/login'/>
};

export default ProtectedRoute;
