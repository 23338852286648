import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import API from "../api/api";
import { Button, Paper, Box, InputAdornment} from '@mui/material'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroller';
import Autocomplete from '@mui/material/Autocomplete';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';

class Stations extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        stations: [],
        items: [],
        page: 1,
        country: "FR",
        ids: [],
        end: false,
      };
    }

    async loadSettings() {
        const settings = JSON.parse(localStorage.getItem('settings'));
        this.setState({
            country: settings.preferedCountry,
        });

    }
  
    componentDidMount() {
        this.loadSettings().then(() => {
            this.fetchData();
        });
    }
    
    fetchMoreData = () => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        this.setState({
            page: this.state.page + 1,
            items: this.state.stations.slice(0, this.state.page * 20)
        });

      };

    
    

    fetchData = () => {
        API.get(`station/read_cc/${this.state.country}`)
        .then(res => {
            this.setState({
                loading: false,
                stations: res.data.data,
                items: res.data.data.slice(0, 20),
            });
            for (let i = 0; i < this.state.stations.length; i++) {
                this.state.ids.push(this.state.stations[i].name);
            }
        })
        .catch(err => {
            console.log(err);
        });

    };
  
    
    render() {

      return (
        <>
         {!this.state.loading ? <>
          {this.state.error ? <div>{this.state.error}</div> : 
            <>
            
            <Box 
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            padding={2}
            >
                <Box sx={{ textAlign: 'center' }}><h1>All stations</h1></Box>
            </Box>
            <Box padding={2}>
                <Box 
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                padding={2}
                
                >
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={this.state.ids}
                    sx={{ width: 300 }}
                    getOptionLabel={option => option}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Search station"
                            variant="outlined"
                            onChange={(e) => {
                                let stations = document.getElementsByClassName("stationCard")
                                let stationsToFound = document.querySelectorAll(`[id*='${e.target.value}']`)
                                
                                
                                if(e.target.value != ""){
                                    for (var i = 0; i < stations.length; i++) {
                                        stations[i].style.display = "none"
                                      }
                                    for (var i = 0; i < stationsToFound.length; i++) {
                                    stationsToFound[i].style.display = "block"
                                    }
                                } else {
                                    for (var i = 0; i < stations.length; i++) {
                                        stations[i].style.display = "block"
                                      }
                                }
                                console.log(e.target.value)
                               
                            }}   
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            />
                    )}
                />  
                </Box>
            </Box>
            
            <InfiniteScroll
                    pageStart={0}
                    loadMore={this.fetchMoreData}
                    hasMore={this.state.items.length < this.state.stations.length ? true : false}
                    loader={<div className="loader" key={0}>
                        
                        <Box 
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                    >
                    
                </Box></div>}
                >
            <div id="content">
            
            <Grid container spacing={1}>
            <Box
              sx={{
                p: 0,
                bgcolor: 'background.default',
                display: 'grid',
                width: '80%',
                margin: 'auto',
                gridTemplateColumns: { md: '1fr 1fr 1fr 1fr 1fr' },
                gap: 3,
              }}
            >
                
                {this.state.items.map(station => (
                    <Zoom in={true} style={{ transitionDelay: '500ms' }} >
                        <Paper>
                            <Card id={station.name} class="stationCard" key={station.name}>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        Name: {station.name}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Country: {station.country_code}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Region: {station.region}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Location: {station.latitude}, {station.longitude}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Postalcode: {station.postalcode}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Elevation: {station.elevation}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" color="secondary" component={Link} to={"/station/"+station.name}>Open</Button>
                                </CardActions>
                            </Card>
                        </Paper>
                    </Zoom>
                ))}
                
            </Box>
            </Grid>
            
            </div>
            </InfiniteScroll>
            <br></br>
            </> } </> : 
            <Box 
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                padding={2}
                >
                <CircularProgress />
            </Box>
         
         }     
        </>
    
      );
    }
  }
  
  
  export default Stations;
