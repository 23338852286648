import React from "react";
import { useParams } from 'react-router';
import { Link } from "react-router-dom";
import API from "../api/api";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AttributionIcon from '@mui/icons-material/Attribution';
import Divider from '@mui/material/Divider';
import { Button, Paper, Box} from '@mui/material'
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LandscapeIcon from '@mui/icons-material/Landscape';
import HouseIcon from '@mui/icons-material/House';
import CloseIcon from '@mui/icons-material/Close';
import LineGraph from "./Charts/LineGraph";
import CircularProgress from '@mui/material/CircularProgress';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
  }
  
class Station extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        stationName: "",
        stationID: 0,
        stationCountry: "",
        stationLat: 0,
        stationLong: 0,
        stationPostalCode: "",
        stationElevation: 0,
        stationRegion: "",
        stationLevelType: "",
      };
      
    }
  
    componentDidMount() {
        let { id } = this.props.params;
        this.fetchData(id);
    }

    fetchData = id => {
        API.get(`station/read_one/${id}`)
        .then(res => {
            this.setState({
                loading: false,
                stationID: id,
                stationName: res.data.data.name,
                stationCountry: res.data.data.country_code,
                stationLat: res.data.data.latitude,
                stationLong: res.data.data.longitude,
                stationPostalCode: res.data.data.postalcode,
                stationElevation: res.data.data.elevation,
                stationRegion: res.data.data.region,
                stationLevelType: res.data.data.level_type,
            });
        })
        .catch(err => {
            console.log(err);
            this.setState({
                loading: false,
                stationID: id,
                error: "Station not found."
            })
        }
        );

    };
  
    
    render() {
      return (
        <>
        <Box component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Button startIcon={<CloseIcon/>} variant="contained" color="secondary" component={Link} to={"/"}>Return to Map</Button> 
        </Box>
         {!this.state.loading ? <>
          {this.state.error ? <div>{this.state.error}</div> : 
            <>
            <div id="content">
            <Box sx={{ textAlign: 'center' }}>
                <h1>Station {this.state.stationName} Information</h1>
                <List
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    minWidth: 360
                }}
                >
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <AttributionIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="ID" secondary={this.state.stationName} />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <LanguageIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Country" secondary={this.state.stationCountry} />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LanguageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Region" secondary={this.state.stationRegion} />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LanguageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Type" secondary={this.state.stationLevelType} />
              </ListItem>
              <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <LocationOnIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Latitude, Longitude" secondary={this.state.stationLat + ", " + this.state.stationLong}  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <LandscapeIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Elevation" secondary={this.state.stationElevation}  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <HouseIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Postalcode" secondary={this.state.stationPostalCode}  />
                </ListItem>
                
                </List>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", maxwidth: '50%' }}>
              <Paper sx={{ textAlign: 'center', minWidth: '40%', maxWidth: "40%", margin: '5px', padding: '5px' }}>
                  <h1>Air pressure</h1>
                  <LineGraph station={this.state.stationID} type={"air_pressure"} />
              </Paper>
              <Paper sx={{ textAlign: 'center', minWidth: '40%', maxWidth: "40%", margin: '5px', padding: '5px'  }}>
                  <h1>Temperature</h1>
                  <LineGraph station={this.state.stationID} type={"temp"} />
              </Paper>
              <Paper sx={{ textAlign: 'center', minWidth: '40%', maxWidth: "40%", margin: '5px', padding: '5px'   }}>
                  <h1>Windspeed</h1>
                  <LineGraph station={this.state.stationID} type={"wind_speed"}/>
              </Paper>
              <Paper sx={{ textAlign: 'center', minWidth: '40%', maxWidth: "40%", margin: '5px', padding: '5px'  }}>
                  <h1>Wind direction</h1>
                  <LineGraph station={this.state.stationID} type={"rain"}/>
              </Paper>
            </Box>
            

            </div>
            </> } </> : 
            <Box sx={{ textAlign: 'center' }}>
                <CircularProgress />
            </Box>
         
         }     
        </>
    
      );
    }
  }
  
  
  export default withParams(Station);
