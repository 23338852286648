// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../node_modules/leaflet/dist/leaflet.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../node_modules/react-leaflet-markercluster/dist/styles.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".markercluster-map {\n  height: 92vh;\n  width: 100%;\n}\n\n#sideBar {\n  display: none;\n  width: 30%;\n  height: 92vh;\n  right: 0px;\n  padding: 10px;\n}\n\n#content {\n  display: flex;\n}\n\n.leaflet-div-icon {\n  background: none;\n  border: none;\n}\n\n:root {\n  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);\n}\n\n@media (prefers-color-scheme: dark) {\n  .map-tiles {\n    filter: var(--map-tiles-filter, none);\n  }\n}", "",{"version":3,"sources":["webpack://./src/Components/style.scss"],"names":[],"mappings":"AAGA;EACI,YAAA;EACA,WAAA;AAAJ;;AAGA;EACE,aAAA;EACA,UAAA;EACA,YAAA;EACA,UAAA;EACA,aAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,gBAAA;EACA,YAAA;AAAF;;AAIA;EACE,0GAAA;AADF;;AAIA;EACE;IACI,qCAAA;EADJ;AACF","sourcesContent":["@import \"leaflet/dist/leaflet.css\";\n@import \"~react-leaflet-markercluster/dist/styles.min.css\";\n\n.markercluster-map {\n    height: 92vh;\n    width: 100%;\n  }\n\n#sideBar {\n  display: none;\n  width: 30%;\n  height: 92vh;\n  right: 0px;\n  padding: 10px;\n  \n}\n#content {\n  display: flex;\n  \n}\n.leaflet-div-icon {\n  background: none;\n  border: none;\n}\n\n\n:root {\n  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);\n}\n\n@media (prefers-color-scheme: dark) {\n  .map-tiles {\n      filter:var(--map-tiles-filter, none);\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
