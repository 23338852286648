import React from 'react';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import { Line } from 'react-chartjs-2';
import API from "../../api/api"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

class LineGraph extends React.Component {

    constructor(props) {
        super(props);

        this.type = props.type

        switch (this.type){
            case "temp":
                this.text = "Temperature"
                break;
            case "rain":
                this.text = "Wind direction"
                break;
            case "wind_speed":
                this.text = "Wind Speed"
                break;
            default:
                this.text = "Air Pressure";
                break;
        }

        if (props.station !== null){
            this.url = "weather_data/read_one/" + props.station
        }

        this.state = {
            loading: true,
        };

        this.options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    text: this.text,
                    display: true,
                },
            },
        };

    }

    componentDidMount() {
            let type = this.type

            API.get(this.url).then(response => {

                

                function parseData(data) {
                    let values = []
                    let labels = []
                    for (let i = data.length - 1; i > 0; i--) {
                        let obj = data[i];

                        switch (type){
                            case "temp":
                                values.push(obj.temperature)
                                break;
                            case "rain":
                                values.push(obj.wind_direction)
                                break;
                            case "wind_speed":
                                values.push(obj.wind_speed)
                                break;
                            default:
                                values.push(obj.station_air_pressure)
                                break;
                        }


                        labels.push(obj.timestamp.substring(11))
                    }

                    return {
                        values,
                        labels
                    }
                }

                let obj = parseData(response.data.data)

                let color

                switch (type){
                    case "temp":
                        color = 'rgb(210,108,44)'
                        break;
                    case "rain":
                        color = 'rgb(40,88,122)'
                        break;
                    case "wind_speed":
                        color = 'rgb(0,157,255)'
                        break;
                    default:
                        color = 'rgb(123,190,53)'
                        break;
                }

                this.data = {
                    labels: obj.labels,
                    datasets: [{
                        fill: true,
                        label: this.text,
                        backgroundColor: color,
                        borderColor: color,
                        data: obj.values
                    }]
                }

                this.setState({
                    loading: false,
                });
            })
        }



    render() {
        if (this.state.loading === true){
            return (
                <>
                    <p>Loading ...</p>
                </>
            );
        }

        return (
            <>
                <h1>{this.data.datasets.data}</h1>
                <Line options={this.options} data={this.data}/>
            </>
        )
    }

}

export default LineGraph;