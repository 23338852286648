import React from "react";
import { Link } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import AttributionIcon from '@mui/icons-material/Attribution';
import Divider from '@mui/material/Divider';
import { Button, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import API from "../api/api";

class Settings extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        stations: [],
        error: null,
        settings: {
            lastLocation: "",
            language: "",
            preferedCountry: "",
            mapType: "",
            allCountries: [],
            acLoading: true
        },
      };
    }
  
    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        API.get("countries").then(response => {
            this.setState({
                acLoading: false,
                allCountries: response.data.data,
            })}).then( () => {
                console.log(this.state.allCountries);
                
                const currentSettings = localStorage.getItem("settings")
                if (currentSettings) {
                    const settings = JSON.parse(currentSettings);
                    this.setState({
                        loading: false,
                        settings: {
                            lastLocation: settings.lastLocation,
                            language: settings.language,
                            preferedCountry: settings.preferedCountry,
                            mapType: settings.mapType,

                        }
                        
                    });
                } else {
                    this.setState({
                        loading: false,
                        settings: {
                            language: "English",
                            lastLocation: true,
                            preferedCountry: "FR",
                            mapType: "default"
                        }
                    });
                    localStorage.setItem("settings", JSON.stringify({
                        language: "English",
                        lastLocation: true,
                        preferedCountry: "FR",
                        mapType: "default"
                    }));
                    
                }
            }).catch(error => {
                this.setState({
                    loading: false,
                    error: error
                });
    })
    
};  
  
    
    render() {
        
        const updateLS = (type, data) => {
            var newSettings;
            switch (type) {
                case "language":
                    newSettings = JSON.stringify({
                        "lastLocation": this.state.settings.lastLocation,
                        "language": data,
                        "preferedCountry": this.state.settings.preferedCountry,
                        "mapType": this.state.settings.mapType
                    })
                    break;
                case "lastLocation":
                    newSettings = JSON.stringify({
                        "lastLocation": data,
                        "language": this.state.settings.language,
                        "preferedCountry": this.state.settings.preferedCountry,
                        "mapType": this.state.settings.mapType
                    })
                    break;
                case "preferedCountry":
                    newSettings = JSON.stringify({
                        "lastLocation": this.state.settings.lastLocation,
                        "language": this.state.settings.language,
                        "preferedCountry": data,
                        "mapType": this.state.settings.mapType
                    })
                    break;
                case "mapType":
                    newSettings = JSON.stringify({
                        "lastLocation": this.state.settings.lastLocation,
                        "language": this.state.settings.language,
                        "preferedCountry": this.state.settings.preferedCountry,
                        "mapType": data
                    })
                    break;
                default:
                    break;
            }

            localStorage.setItem("settings", newSettings);

        }
        const handleChangeCountry = (event) => {
            this.setState({
                settings: {
                    lastLocation: this.state.settings.lastLocation,
                    language: this.state.settings.language,
                    preferedCountry: event.target.value,
                    mapType: this.state.settings.mapType
                }
            });
            updateLS("preferedCountry", event.target.value);
        }

        const handleChangeLocation = (event) => {
            this.setState({
                settings: {
                    lastLocation: event.target.checked,
                    language:this.state.settings.language,
                    preferedCountry: this.state.settings.preferedCountry,
                    mapType: this.state.settings.mapType
                }
            });
            updateLS("lastLocation", this.state.settings.lastLocation);
        };

        const handleChangeLanguage = (event) => {
            this.setState({
                settings: {
                    lastLocation: this.state.settings.lastLocation,
                    language: event.target.value,
                    preferedCountry: this.state.settings.preferedCountry,
                    mapType: this.state.settings.mapType
                }
            });
            updateLS("language", event.target.value);              
        };

        const handleChangeMapType = (event) => {
            this.setState({
                settings: {
                    lastLocation: this.state.settings.lastLocation,
                    language: this.state.settings.language,
                    preferedCountry: this.state.settings.preferedCountry,
                    mapType: event.target.value
                }
            });
            updateLS("mapType", event.target.value);
        };

      return (
        <>
         {!this.state.loading ? <>
          {this.state.error ? <div>{this.state.error}</div> : 
            <>
                <List>
                    <Box 
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        m={1}
                        bgcolor="background.paper"
                        borderColor="text.primary"
                    >
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box mr={1}>
                                <Avatar>
                                    <AttributionIcon />
                                </Avatar>
                            </Box>
                            <Box>
                                <Typography variant="h6">
                                    Settings
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Button component={Link} to="/">
                                <CloseIcon />
                            </Button>
                        </Box>
                    </Box>
                    <Divider />
                    <ListItem>
                        <ListItemText primary="Last Location" secondary={this.state.settings.lastLocation ? "On" : "Off"} />
                        <ListItemSecondaryAction>
                            <Switch
                                edge="start"
                                onChange={handleChangeLocation}
                                checked={this.state.settings.lastLocation}
                                value={this.state.settings.lastLocation}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        
                        <ListItemText primary="Language" secondary={this.state.settings.language} />
                        <ListItemSecondaryAction>
                            <Select
                                value={this.state.settings.language}
                                onChange={handleChangeLanguage}
                                inputProps={{
                                    name: 'language',
                                    id: 'language-select',
                                }}
                            >
                                <MenuItem value="English">English</MenuItem>
                                <MenuItem value="Dutch">Dutch</MenuItem>
                                <MenuItem value="French">French</MenuItem>
                            </Select>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary="Map Type" secondary={this.state.settings.mapType} />
                        <ListItemSecondaryAction>
                            <Select
                                value={this.state.settings.mapType}
                                onChange={handleChangeMapType}
                                inputProps={{
                                    name: 'mapType',
                                    id: 'mapType-select',
                                }}
                            >
                                <MenuItem value="default">Default</MenuItem>
                                <MenuItem value="satellite">Satellite</MenuItem>
                                <MenuItem value="terrain">Terrain</MenuItem>
                                <MenuItem value="watercolor">Watercolor</MenuItem>
                                <MenuItem value="dark_matter">Dark Matter</MenuItem>
                                <MenuItem value="toner">Toner</MenuItem>
                                <MenuItem value="tonerlite">Toner Lite</MenuItem>
                                <MenuItem value="toner-background">Toner Background</MenuItem>
                                <MenuItem value="toner-hybrid">Toner Hybrid</MenuItem>
                                <MenuItem value="toner-labels">Toner Labels</MenuItem>
                                <MenuItem value="toner-lines">Toner Lines</MenuItem>


                            </Select>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        
                        <ListItemText primary="Country" secondary={this.state.settings.preferedCountry} />
                        <ListItemSecondaryAction>
                                <Select
                                value={this.state.settings.preferedCountry}
                                onChange={handleChangeCountry}
                                inputProps={{
                                    name: 'country',
                                    id: 'country-select',
                                }}
                            >
                                {this.state.allCountries.map((country, index) => (
                                    <MenuItem value={country.country_code} key={index}>{country.name}</MenuItem>
                                ))}
                                
                                
                            </Select>
                                
                            
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </>}
            </> : <div>Loading...</div>}
        </>
        );
    }
  }

  
  export default Settings;
