import React from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import EmailIcon from '@mui/icons-material/Email';


class Profile extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        email: "",
        firstName: "",
        lastName: "",
        avatar: ""
      };
    }

    componentDidMount() {
        fetch("https://reqres.in/api/users/4")
        .then(Respone => Respone.json())
        .then(info => info.data)
        .then(data => {
            this.setState({
                loading: false,
                email: data.email,
                firstName: data.first_name,
                lastName: data.last_name,
                avatar: data.avatar
            })
        }).catch(err => {
            console.log(err);
            this.setState({
                loading: false,
                email: "boop",
                error: "email not found."
            })
        }
        )
    }
    
    
    render() {
        
        return ( <>
            {!this.state.loading ? <>
                {this.state.error ? <div>{this.state.error}</div> : 
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        padding: "1rem"
                    }}> 
                   <Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        padding: "1rem"
                    }}>

                    <Avatar alt="Eve" 
                        src={"https://reqres.in/img/faces/4-image.jpg"}
                        />
                    </Box>

                        <h1>Profile {this.state.firstName} {this.state.lastName}</h1>
                        <List
                            sx={{ width: '100%', }}
                        >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar />
                            </ListItemAvatar>
                            <ListItemText primary="First Name" secondary={this.state.firstName} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar/>
                            </ListItemAvatar>
                            <ListItemText primary="Last Name" secondary={this.state.lastName} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemAvatar>
                            <Avatar>
                                    <EmailIcon />
                                </Avatar>
                                    
                            </ListItemAvatar>
                            <ListItemText primary="Email" secondary={this.state.email} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                    </Box>
                    </Box> 
            } </> : 
            <Box sx={{ textAlign: 'center' }}>
                <CircularProgress />
            </Box>
         
         }     
        </>      
        )
    }
  }

  
  export default Profile;
