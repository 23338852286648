import React from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AttributionIcon from '@mui/icons-material/Attribution';
import Divider from '@mui/material/Divider';
import { Button, Box, IconButton} from '@mui/material'
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LandscapeIcon from '@mui/icons-material/Landscape';
import HouseIcon from '@mui/icons-material/House';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from "react-router-dom";

class InfoStation extends React.Component {
    closeSideBar = () => {
        var sideBar = document.getElementById("sideBar");
        document.getElementsByClassName("markercluster-map")[0].style.zIndex = "99";
        sideBar.style.display = "none";
        localStorage.removeItem("stationData");
    }
    render() {
        return (
            <>
            <Box component="span"
                m={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center">
              <Button startIcon={<CloseIcon/>} variant="contained" color="secondary" onClick={this.closeSideBar}>Close</Button> 
              <IconButton component={Link} to={"/station/"+this.props.states.stationName}><OpenInNewIcon/></IconButton>
            </Box>
            <Box sx={{ textAlign: 'center' }}><h1>Station {this.props.states.stationName} Information</h1></Box>
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <AttributionIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="ID" secondary={this.props.states.stationName} />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LanguageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Country" secondary={this.props.states.stationCountry} />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LanguageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Region" secondary={this.props.states.stationRegion} />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LanguageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Type" secondary={this.props.states.stationLevelType} />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LocationOnIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Latitude, Longitude" secondary={this.props.states.stationLat + ", " + this.props.states.stationLong}  />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LandscapeIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Elevation" secondary={this.props.states.stationElevation}  />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <HouseIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Postalcode" secondary={this.props.states.stationPostalCode}  />
              </ListItem>
              
            </List>
            <Box sx={{ textAlign: 'center' }}><Divider /></Box>
            {/* <LineGraph states={this.props.states} /> */}
            </>
        );
    }
}
export default InfoStation;
